<template>
    <div class="mobile-title">
        <p>השיטה</p>
    </div>
    <div class="method" id="method">
        <div class="step">
            <div class="step-img">
                <img src="https://image.flaticon.com/icons/png/512/3214/3214781.png" alt="">
            </div>
            <div class="step-title">
                <div class="step-number">
                    <div class="number">
                        01
                    </div>
                </div>
                <div class="step-name">
                    <div class="name">
                        היכרות וגילוי
                    </div>
                </div>
            </div>
            <div class="step-desc">
                <p style="font-weight:500;">היכרות בין הצוותים והגורמים הרלוונטיים לפרויקט.</p><br>
                <p> נבחן את היכולות שלנו בעניין הפרויקט הרצוי ונאפיין את הבעיה/הצורך שאותם אנו מעוניינים לפתור.</p>
            </div>
            <div class="key-procceses">
                <div class="key-icon" style="background-color:var(--bg-yellow)">
                    <i class="el-icon-key"></i>
                </div>
                <p>תהליכי מפתח</p>
            </div>
            <div class="key-tags">
                <el-tag type="warning" class="tag">היכרות הדדית</el-tag>
                <el-tag type="warning" class="tag">אפיון הבעיה \ הצורך</el-tag>
                <el-tag type="warning" class="tag">סקירת התחרות</el-tag>
                <el-tag type="warning" class="tag">סיור מוחות</el-tag>
                <el-tag type="warning" class="tag">שיתוף מידע</el-tag>
            </div>
        </div>
        <div class="step">
            <div class="step-img">
                <img src="https://image.flaticon.com/icons/png/512/1754/1754652.png" alt="">
            </div>
            <div class="step-title">
                <div class="step-number">
                    <div class="number" style="color:var(--bg-blue)">
                        02
                    </div>
                </div>
                <div class="step-name">
                    <div class="name">
                        מיפוי ואפיון
                    </div>
                </div>
            </div>
            <div class="step-desc">
                <p style="font-weight:500;">אפיון המוצר בשיתוף פעולה עם הלקוח.</p><br>
                <p>לאחר ההיכרות והגילוי נתחיל בתהליך האפיון, עד לפרטים הקטנים ביותר. נאפיין גם את האסטרטגיה, קווי היסוד והטכנולוגיה.</p>
            </div>
            <div class="key-procceses">
                <div class="key-icon" style="background-color:var(--bg-blue)">
                    <i class="el-icon-key"></i>
                </div>
                <p>תהליכי מפתח</p>
            </div>
            <div class="key-tags">
                <el-tag type="primary" class="tag">הגדרת אסטרטגיה</el-tag>
                <el-tag type="primary" class="tag">בחירת טכנולוגיה</el-tag>
                <el-tag type="primary" class="tag">אפיון הפתרון וחלוקה למרכיבים</el-tag>
                <el-tag type="primary" class="tag">הדמיה ויזואלית</el-tag>
            </div>
        </div>
        <div class="step">
            <div class="step-img">
                <img src="https://image.flaticon.com/icons/png/512/1006/1006363.png" alt="">
            </div>
            <div class="step-title">
                <div class="step-number">
                    <div class="number" style="color:var(--bg-green)">
                        03
                    </div>
                </div>
                <div class="step-name">
                    <div class="name">
                        תחילת פיתוח
                    </div>
                </div>
            </div>
            <div class="step-desc">
                <p style="font-weight:500;">סייבר פארק תחל בפיתוח המוצר.</p><br>
                <p>הצוות יחל בתהליך הפיתוח ויתבסס על העקרונות, האסטרטגיה והקווים המנחים אשר נקבעו בשלבים הקודמים.</p>
            </div>
            <div class="key-procceses">
                <div class="key-icon" style="background-color:var(--bg-green)">
                    <i class="el-icon-key"></i>
                </div>
                <p>תהליכי מפתח</p>
            </div>
            <div class="key-tags">
                <el-tag type="success" class="tag">פיתוח אב טיפוס</el-tag>
                <el-tag type="success" class="tag">ביצוע בדיקות וסקירות מערכת</el-tag>
                <el-tag type="success" class="tag">הצמדות לתכנית</el-tag>
                <el-tag type="success" class="tag">פיתוח בקצב מהיר</el-tag>
                <el-tag type="success" class="tag">שיתוף הלקוח</el-tag>
            </div>
        </div>
        <div class="step">
            <div class="step-img">
                <img src="https://image.flaticon.com/icons/png/512/1055/1055673.png" alt="">
            </div>
            <div class="step-title">
                <div class="step-number">
                    <div class="number" style="color:var(--bg-red)">
                        04
                    </div>
                </div>
                <div class="step-name">
                    <div class="name">
                        משוב אמצע
                    </div>
                </div>
            </div>
            <div class="step-desc">
                <p style="font-weight:500;">ביקורת ומשוב אמצע הם מפתח להצלחת הפרויקט.</p><br>
                <p>אנו מאמינים שככל שהלקוח מעורב יותר בתהליך הפיתוח, כך המוצר מגשים טוב יותר את החזון המקורי.</p>
            </div>
            <div class="key-procceses">
                <div class="key-icon" style="background-color:var(--bg-red)">
                    <i class="el-icon-key"></i>
                </div>
                <p>תהליכי מפתח</p>
            </div>
            <div class="key-tags">
                <el-tag type="danger" class="tag">בחינה וסקירה של האב טיפוס</el-tag>
                <el-tag type="danger" class="tag">ביצוע התאמות לתכנית המקורית</el-tag>
                <el-tag type="danger" class="tag">העלאת נושאים חשובים מהליך הפיתוח</el-tag>
            </div>
        </div>
        <div class="step">
            <div class="step-img">
                <img src="https://image.flaticon.com/icons/png/512/1505/1505465.png" alt="">
            </div>
            <div class="step-title">
                <div class="step-number">
                    <div class="number" style="color:var(--bg-yellow)">
                        05
                    </div>
                </div>
                <div class="step-name">
                    <div class="name">
                        סיום פיתוח
                    </div>
                </div>
            </div>
            <div class="step-desc">
                <p style="font-weight:500;">סיום הפיתוח והגשת המוצר הסופי ללקוח.</p><br>
                <p>הצוות יארוז את המוצר ויכין אותו למסירה ללקוח.</p>
                <p>ככל ויש צורך, בשלב זה מוטמעים גם כלים חיצוניים לניהול ובדיקה.</p>
            </div>
            <div class="key-procceses">
                <div class="key-icon" style="background-color:var(--bg-yellow)">
                    <i class="el-icon-key"></i>
                </div>
                <p>תהליכי מפתח</p>
            </div>
            <div class="key-tags">
                <el-tag type="warning" class="tag">אריזת המוצר למצבו סופי</el-tag>
                <el-tag type="warning" class="tag">ביצוע התאמות אחרונות ("פינישים")</el-tag>
                <el-tag type="warning" class="tag">העברת המוצר לבדיקה חיצונית (QA)</el-tag>
            </div>
        </div>
        <div class="step">
            <div class="step-img">
                <img src="https://image.flaticon.com/icons/png/512/2706/2706950.png" alt="">
            </div>
            <div class="step-title">
                <div class="step-number">
                    <div class="number" style="color:var(--bg-blue)">
                        06
                    </div>
                </div>
                <div class="step-name">
                    <div class="name">
                        ליווי ומעקב
                    </div>
                </div>
            </div>
            <div class="step-desc">
                <p style="font-weight:500;">שמירה על קשר עם הלקוח ומעקב אחר המוצר.</p><br>
                <p>קשה לחזות את התנהגות המוצר לאחר שהוא יוצא לשוק, המשתמשים יכולים לייצר מצבים אשר לא צפינו מראש ויהיה צורך לבצע התאמות נוספות.</p>
            </div>
            <div class="key-procceses">
                <div class="key-icon" style="background-color:var(--bg-blue)">
                    <i class="el-icon-key"></i>
                </div>
                <p>תהליכי מפתח</p>
            </div>
            <div class="key-tags">
                <el-tag type="primary" class="tag">קשר רציף</el-tag>
                <el-tag type="primary" class="tag">מענה מהיר ללקוח</el-tag>
                <el-tag type="primary" class="tag">תמיכה טכנית</el-tag>
                <el-tag type="primary" class="tag">בדיקות שגרתיות</el-tag>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted } from '@vue/runtime-core';

export default {
components:{},
setup(){
    onMounted(() => {
        const method = document.getElementById('method');
        if(method){
            method.classList.toggle('fade-in')
        }
    })
    
    return{

    }
}
}
</script>

<style scoped>
.method{
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 0.33fr 0.33fr 0.33fr;
    grid-template-rows: 50% calc(50% - 20px);
    gap: 10px;
    overflow: hidden;
    padding: 65px;
    justify-content: center;
}
.step{
    position: relative;
    width: calc(100%);
    height: calc(100%);
    max-height: 430px;
    padding: 40px;
    background-color: rgb(248, 248, 248);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;
    overflow: hidden;
    transition: ease-in 0.2s;
    -moz-box-shadow: 0 0 3px #ccc;
    -webkit-box-shadow: 0 0 3px #ccc;
    box-shadow: 0 0 3px #ccc;
}
.step:hover{
    background-color: rgb(238, 238, 238);
}
.step-img{
    position: absolute;
    top: 20px;
    align-self: center;
    height: 50px;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.step-img img{
    max-width: 100%;
    max-height: 100%;
}
.step-title{
    display: flex;
    margin-top: 40px;
    align-items: flex-end;

}
.step-number{
    box-sizing: border-box;
}
.number{
    margin-left: 15px;
    color: var(--bg-yellow);
    font-size: 32px;
    letter-spacing: 2.3px;
}
.name{
    color: #242424;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1.3px;
}
.step-desc{
    margin-top: 10px;
    letter-spacing: 1px;
    color: #242424;
    height: 35%;
}
.key-procceses{
    margin: 15px 0px 15px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.key-procceses p{
    font-weight: 500;
    color: #242424;
}
.key-icon{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}
.key-tags{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.tag{
    margin-left: 5px;
    margin-top: 5px;
    font-size: 14px;
}
.mobile-title{
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100%);
    height: 70px;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
    display: none;
    z-index: 1;
    font-size: 4vh;
}
@media only screen and (max-width: 1004px) {
    .mobile-title{
        display: flex;
    }
    .method{
        position: relative;
        width: calc(100%);
        height: calc(100% - 70px);
        margin-top: 70px;
        grid-template-areas: 
        "step-1"
        "step-2"
        "step-3"
        "step-4"
        "step-5"
        "step-6";
        grid-template-columns: 1fr;
        grid-template-rows: 430px 430px 430px 430px 430px 430px;
        overflow-y: auto;
        padding: 10px 10px 10px 10px;
    }
}

.fade-in {
	-webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>